import { render, staticRenderFns } from "./AdminLivres.vue?vue&type=template&id=db098332&scoped=true"
import script from "./AdminLivres.vue?vue&type=script&lang=js"
export * from "./AdminLivres.vue?vue&type=script&lang=js"
import style0 from "./AdminLivres.vue?vue&type=style&index=0&id=db098332&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "db098332",
  null
  
)

export default component.exports